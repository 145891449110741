import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { useRouter } from '@abyss/web/hooks/useRouter';
import apiHelper from '@src/common/helpers/apiHelper';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { BASE_URL } from '../utils/endpoints';
import {
  MlreDataTable,
  DataTableSearchLabel,
  MlreLoader,
} from '../../common/MlreAbyssComponent';
import { ConfirmModal, ApprovePaymentModal } from './Modal';
import env from '../../../env.json';

export const AggregationGroupDataTable = ({ searchData }) => {
  const { token, userRolesGroups, msid } = useAuth();

  const [openConfirmModal, setOpenCofirmModal] = useState(false);
  const [openApprovePaymentModal, setOpenApprovePaymentModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [selectedLinkID, setSelectedLinkID] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentPayload, setPaymentPayload] = useState({});
  const { navigate, getLocation } = useRouter();
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [isCheckedAccess, setIsCheckedAccess] = useState(false);
  const [currentYear, setCurrentYear] = useState('');

  const handleOnConfirm = () => {
    setOpenApprovePaymentModal(true);
  };

  useEffect(() => {
    const hasAccessHoldPayment = env.AG_Screen_Hold_Payment.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasAccessHoldPayment) {
      setIsCheckboxDisabled(true);
    } else {
      setIsCheckboxDisabled(false);
    }

    const hasAccessApprovePayment = env.AG_Screen_Approve_Payment.some(
      (role) => {
        return userRolesGroups.includes(role);
      }
    );

    if (!hasAccessApprovePayment) {
      setIsCheckedAccess(true);
    } else {
      setIsCheckedAccess(false);
    }
  }, [userRolesGroups]);

  const fetchData = async () => {
    if (!currentYear) {
      try {
        const result = await axios.get(
          `${BASE_URL}subscriber-clone/getcurrentYear`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setCurrentYear(result.data);
      } catch (error) {
        console.log(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    }
    try {
      const result = await axios.post(
        `${BASE_URL}aggregation-group/search-ag-with-params`,
        searchData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTableData(result.data);
      console.log(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  // const getRebateYear = async () => {

  //   // setCurrentYear('2023');
  // };

  const processPayment = async (approverDate) => {
    const url =
      modalType == 'Hold Payment'
        ? `${BASE_URL}aggregation-group/hold-payment-status`
        : `${BASE_URL}aggregation-group/approve-payment-status`;

    try {
      const result = await axios.post(
        url,
        {
          ...paymentPayload,

          approverDate,
          aggrGrpId: selectedRow?.AggrGrpId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setOpenApprovePaymentModal(false);
      setPaymentPayload({});
      setTimeout(() => {
        fetchData();
      }, 1000);
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (searchData) {
      fetchData();
    }
  }, [searchData]);

  useEffect(() => {
    if (selectedID) {
      setSelectedRow(tableData[selectedID]);
    }
  }, [selectedID]);

  useEffect(() => {
    if (selectedLinkID) {
      const selectedRowData = tableData[selectedLinkID];
      const payload = {
        rbtRptYr: searchData.rbtRptYr,
        runTypCd: searchData.runTypCd,
        runTypSeqNbr: searchData.runTypSeqNbr,
        mlrCseSz: selectedRowData.mlrCseSz,
        mlrSitusCd: selectedRowData.mlrSitusCd,
        lglEntyId: selectedRowData.lglEntyId,
      };
      const breadcrums = [
        { title: 'AG Search', href: '/aggregation-group/' },
        {
          title: 'AG Result',
          href: '/aggregation-group#table',
          state: {
            breadcrumsData: searchData,
          },
        },
      ];
      navigate('/aggregation-group-operation', {
        state: { payload, breadcrums },
      });
    }
  }, [selectedLinkID]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Link',
        accessor: 'col0',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          return (
            <Button
              rounded
              size="$sm"
              onClick={() => {
                setSelectedLinkID(row.id);
              }}
            >
              <IconMaterial icon="arrow_forward" />
            </Button>
          );
        },
      },
      {
        Header: 'Hold Payment',
        accessor: 'payment',
        disableSortBy: false,
        Cell: ({ value, cellActions, row, ...props }) => {
          const isDisabled =
            isCheckboxDisabled ||
            !(row.original.atr4 === 'Y') ||
            !(row.original.atr1 === 'Y');
          return (
            <label htmlFor={row.id}>
              <input
                id={row.id}
                type="checkbox"
                disabled={isDisabled}
                onChange={() => {
                  setSelectedID(row.id);
                  setModalType('Hold Payment');
                  setPaymentPayload({
                    approvalStatus: 'N',
                    loginId: msid,
                    modifiedDate: row.original.modifiedDate,
                  });

                  setOpenCofirmModal(true);
                  // cellActions.modifyRow(row, {
                  //   payment2: { atr1: 'Y', atr4: value.atr4 },
                  // });
                }}
              />
              <IconSymbol
                color={isDisabled ? '$interactiveCb' : '$interactive1'}
                icon={
                  value.atr1 === 'Y' ? 'check_box' : 'check_box_outline_blank'
                }
                size="md"
                style={{ cursor: isDisabled ? 'no-drop' : 'pointer' }}
              />
            </label>
          );
        },
      },
      {
        Header: 'Approve Payment',
        accessor: 'payment2',
        disableSortBy: false,
        Cell: ({ value, cellActions, row, ...props }) => {
          let isDisabled =
            isCheckedAccess || !(row.original.calcDoneIndCount > 0);

          isDisabled =
            isDisabled ||
            row.original.atr4 === 'Y' ||
            searchData.runTypCd === 'S' ||
            searchData.rbtRptYr !== currentYear?.year;

          return (
            <label htmlFor={`${row.id}_ap`}>
              <input
                id={`${row.id}_ap`}
                type="checkbox"
                disabled={isDisabled}
                onChange={() => {
                  setModalType('Approve Payment');
                  setOpenCofirmModal(true);
                  setSelectedID(row.id);
                  setPaymentPayload({
                    approvalStatus: 'Y',
                    loginId: msid,
                    modifiedDate: row.original.modifiedDate,
                  });
                  // cellActions.modifyRow(row, {
                  //   payment2: { atr1: value.atr1, atr4: 'Y' },
                  // });
                }}
              />
              <IconSymbol
                color={isDisabled ? '$interactiveCb' : '$interactive1'}
                icon={
                  value.atr4 === 'Y' ? 'check_box' : 'check_box_outline_blank'
                }
                style={{ cursor: isDisabled ? 'no-drop' : 'pointer' }}
                size="md"
              />
            </label>
          );
        },
      },
      {
        Header: 'Legal Entity',
        accessor: 'lglEntyId',
      },
      {
        Header: 'MLR Case Size',
        accessor: 'mlrCseSz',
      },
      {
        Header: 'Situs State Code',
        accessor: 'mlrSitusCd',
      },
      {
        Header: 'Rebatable?',
        accessor: 'InclInRbtInd',
      },
      {
        Header: 'Filed Rebate Amount',
        accessor: 'flRbtAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Adj. Earned Premium Amount',
        accessor: 'adjEpAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Total Earned Premium',
        accessor: 'totalEpAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'MLR Regulated threshold',
        accessor: 'mlrRegdThreshPct',
        Cell: ({ value }) => {
          return `${value}%`;
        },
      },
      {
        Header: 'Aggregate Premium Amount',
        accessor: 'aggrGrpPremAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Rebate Percentage Applied',
        accessor: 'rbtApplPct',
        Cell: ({ value }) => {
          return `${value}%`;
        },
      },
    ];
  }, [isCheckboxDisabled, currentYear]);

  const searchLabel = [
    { fieldName: 'Rebate Reporting Year', value: searchData.rbtRptYr },
    { fieldName: 'Run Type', value: searchData.runTypCd },
    { fieldName: 'Run Sequence', value: searchData.runTypSeqNbr || 'All' },
  ];

  if (isLoading) return <MlreLoader />;

  return (
    <React.Fragment>
      <Router.MetaTags title="Aggregation Group Table" />
      <Grid>
        <Grid.Col span={12}>
          <DataTableSearchLabel searchLabel={searchLabel} />
          <MlreDataTable data={tableData} columns={columns} refreshColumn />
        </Grid.Col>
      </Grid>

      <ConfirmModal
        isOpen={openConfirmModal}
        onClose={() => {
          setOpenCofirmModal(false);
        }}
        modalType={modalType}
        onConfirm={handleOnConfirm}
      />

      <ApprovePaymentModal
        isOpen={openApprovePaymentModal}
        modalType={modalType}
        onClose={() => {
          setOpenApprovePaymentModal(false);
        }}
        approverId={msid}
        searchData={searchData}
        callBack={processPayment}
      />
    </React.Fragment>
  );
};
